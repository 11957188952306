import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { PiPhoneBold, PiPhoneFill } from "react-icons/pi";
import Logo from "../assets/placeholder.svg";

const Navbar = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  const navLinks = [{ to: "#contact", label: "Contact" }];

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <nav className="font-semibold w-full">
        <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="relative flex items-center justify-between">
            <NavLink to="/">
              <img src={Logo} width={144} alt="" />
            </NavLink>
            <div className="lg:hidden">
              <NavLink to="/booking">
                <button className="secondary flex items-center gap-3">
                  <span className="text-[22px]">
                    {location.pathname === "/booking" ? (
                      <PiPhoneFill />
                    ) : (
                      <PiPhoneBold />
                    )}
                  </span>
                  Get Started
                </button>
              </NavLink>
            </div>
            <ul className="hidden lg:flex items-center space-x-8">
              {navLinks.map((link, index) => (
                <NavLink className="nav-link" key={index} to={link.to}>
                  {link.label}
                </NavLink>
              ))}
              <li>
                <NavLink to="/booking">
                  <button className="secondary flex items-center gap-3">
                    <span className="text-[22px]">
                      {location.pathname === "/booking" ? (
                        <PiPhoneFill />
                      ) : (
                        <PiPhoneBold />
                      )}
                    </span>
                    Make A Booking
                  </button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="back-to-top">
        {isVisible && (
          <div onClick={scrollToTop} className="back-to-top-button">
            <FaArrowUp />
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
