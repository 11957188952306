import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";

import Contact from "../components/sections/Contact";
import Location from "../components/sections/Location";

const Booking = () => {
  return (
    <>
      <div className="px-4 pt-16 mx-auto w-full md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
        <div className="mx-auto sm:text-center flex flex-col lg:flex-row w-full lg:gap-x-16 lg:items-center">
          <div className="w-full mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="mb-6 text-5xl lg:text-6xl font-bold text-center lg:text-left leading-none text-dark">
              Book your appointment
            </h2>
            <p className="text-dark text-lg text-center lg:text-left font-medium mb-6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p>
            <div className="flex flex-col lg:flex-row gap-5">
              <div className="flex items-center gap-3 w-full">
                <div className="rounded-2xl p-4 bg-main/30 text-main text-2xl">
                  <BiSolidPhoneCall />
                </div>
                <div>
                  <p className="font-bold text-lg text-dark text-left">
                    Contact us
                  </p>
                  <p className="font-medium text-dark">085 287 8236</p>
                </div>
              </div>
              <div className="flex items-center gap-3 w-full">
                <div className="rounded-2xl p-4 bg-main/30 text-main text-2xl">
                  <MdEmail />
                </div>
                <div>
                  <p className="font-bold text-lg text-dark text-left">
                    Send us mail
                  </p>
                  <p className="font-medium text-dark">contact@example.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 lg:mb-6 h-[600px] w-full rounded-2xl border-2 overflow-hidden bg-white">
            {/* Embed calendar here */}
            <iframe src="https://medkit.neetocal.com/demo" className="w-full h-full" title="Booking Calendar"></iframe>
          </div>
        </div>
      </div>
      <Location />
      <Contact />
    </>
  );
};

export default Booking;
