import React from "react";
import MainBtn from "../buttons/MainBtn";

const About = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-16 lg:grid-cols-2">
        <div className="flex order-1 items-center justify-center -mx-4 lg:pl-8">
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded-3xl sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="https://assets-global.website-files.com/6509c07f4854955ed070a8fa/6514b7aed908967a720585cb_our-team-gallery-one-dentistry-x-webflow-template-p-800.jpg"
              alt=""
            />
          </div>
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded-3xl h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src="https://assets-global.website-files.com/6509c07f4854955ed070a8fa/6514b7adb76ede2f950f0cde_our-team-gallery-two-dentistry-x-webflow-template-p-800.jpg"
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded-3xl sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src="https://assets-global.website-files.com/6509c07f4854955ed070a921/650a19ad5711cb7fb9f525af_four-things-to-consider-before-getting-teeth-braces-default-image-dentistry-x-webflow-template-p-800.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col lg:!order-1 justify-center md:pr-8 xl:pr-0 w-full">
          <div className=" mb-6">
            <h2 className="mb-6 text-5xl font-bold text-center lg:text-left leading-none text-dark">
              Our only mission, make you smile
            </h2>
            <p className="text-dark text-lg text-center lg:text-left font-medium">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p>
          </div>
          <div>
            <MainBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
