import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

// components
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Header from "./components/Header";

// pages
import Landing from "./pages/Landing";
import Booking from "./pages/Booking";

const App = () => {
  return (
    <>
      <Header />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/booking" element={<Booking />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
