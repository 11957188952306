import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Header = () => {
  return (
    <div className="header">
      <section>
        <div className="header-detail">
          <FaPhoneVolume />
          <p>+264 85 287 8236</p>
        </div>
        <div className="header-detail">
          <MdEmail />
          <p>contact@medkit.nam.na</p>
        </div>
      </section>
    </div>
  );
};

export default Header;
