// sections
import Hero from "../components/sections/Hero";
import Location from "../components/sections/Location";
import Contact from "../components/sections/Contact";
import About from "../components/sections/About";
import Team from "../components/sections/Team";
import Service from "../components/sections/Service";

const Landing = () => {
  return (
    <>
      <Hero />
      <About />
      <Location />
      <Service />
      <Contact />
    </>
  );
};

export default Landing;
