import { useEffect, useState } from "react";
import MainBtn from "../buttons/MainBtn";
import Misc from "../../assets/misc.png";
import ScrollToHash from "../ScrollToHash";

const Contact = () => {
  const [navClick, setNavClick] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);
  return (
    <div
      id="contact"
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 border rounded-3xl bg-dark relative overflow-hidden"
    >
      <img src={Misc} alt="" className="absolute bottom-0 z-10 opacity-15 h-full lg:right-0" />
      <div className="max-w-screen-sm sm:text-center sm:mx-auto gap-y-10 flex flex-col z-20">
        <h2 className="text-5xl font-extrabold text-center z-20 leading-none text-main">
          Have an inquiry?
        </h2>
        <p className="text-base text-white md:text-lg font-medium sm:px-4 text-center z-20">
          If you have a general inquiry and would like to speak to our expert
          team, you can contact us on:{" "}
          <span className="text-main font-semibold">085 287 8236</span>
        </p>
        <div className="z-20 mx-auto w-full lg:w-fit">
          <MainBtn />
        </div>
      </div>
      <ScrollToHash />
      </div>
  );
};

export default Contact;
