import React from "react";
import MainBtn from "../buttons/MainBtn";
import { GoCheckCircleFill } from "react-icons/go";
import { FaCheck } from "react-icons/fa";

const Service = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:pr-16 mx-auto mb-10 gap-y-6 flex flex-col">
          <h2 className=" text-5xl font-bold text-center lg:text-left leading-none text-dark">
            Our variety of dental services
          </h2>
          <p className="text-dark text-lg text-center lg:text-left font-medium ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
          <div className="flex flex-col gap-3 ">
            <div className="flex items-center gap-3">
              <GoCheckCircleFill className="text-main min-w-6 h-5" />
              <p className="text-lg text-dark font-medium">
                Businesses looking for a reliable agency that can
                <span className="font-bold">
                  {" "}
                  make their company a priority.
                </span>
              </p>
            </div>
            <div className="flex items-center gap-3">
              <GoCheckCircleFill className="text-main min-w-6 h-5" />
              <p className="text-lg text-dark font-medium">
                Businesses looking to take their offline business
                <span className="font-bold"> online</span>
              </p>
            </div>
          </div>
          <div className="">
            <MainBtn />
          </div>
        </div>
        <div className="grid gap-5 row-gap-5 sm:grid-cols-2">
          <div className="max-w-md bg-white p-5 rounded-3xl">
            <div className="flex items-center justify-center w-16 h-16 rounded-2xl p-4 bg-main text-white text-2xl mb-4 font-extrabold">
              1
            </div>
            <h6 className="mb-2 font-bold leading-5 text-2xl text-dark">
              Orthodontics
            </h6>
            <p className="text-sm text-dark">
              A flower in my garden, a mystery in my panties. Heart attack never
              stopped old Big Bear. I didn't even know we were calling him Big
              Bear.
            </p>
          </div>
          <div className="max-w-md bg-white p-5 rounded-3xl">
            <div className="flex items-center justify-center w-16 h-16 rounded-2xl p-4 bg-main text-white text-2xl mb-4 font-extrabold">
              2
            </div>
            <h6 className="mb-2 font-bold leading-5 text-2xl text-dark">
              Oral hygiene
            </h6>
            <p className="text-sm text-dark">
              Rough pomfret lemon shark plownose chimaera southern sandfish
              kokanee northern sea robin Antarctic cod. Yellow-and-black
              triplefin.
            </p>
          </div>
          <div className="max-w-md bg-white p-5 rounded-3xl">
            <div className="flex items-center justify-center w-16 h-16 rounded-2xl p-4 bg-main text-white text-2xl mb-4 font-extrabold">
              3
            </div>
            <h6 className="mb-2 font-bold leading-5 text-2xl text-dark">
              Care & service
            </h6>
            <p className="text-sm text-dark">
              A slice of heaven. O for awesome, this chocka full cuzzie is as
              rip-off as a cracker. Meanwhile, in behind the bicycle shed,
              Hercules.
            </p>
          </div>
          <div className="max-w-md bg-white p-5 rounded-3xl">
            <div className="flex items-center justify-center w-16 h-16 rounded-2xl p-4 bg-main text-white text-2xl mb-4 font-extrabold">
              4
            </div>
            <h6 className="mb-2 font-bold leading-5 text-2xl text-dark">
              A slice of heaven
            </h6>
            <p className="text-sm text-dark">
              Disrupt inspire and think tank, social entrepreneur but
              preliminary thinking think tank compelling. Inspiring, invest
              synergy capacity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
