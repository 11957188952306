import React from "react";

const Location = () => {
  const latitude = -22.5537286;
  const longitude = 17.0711856;
  const mapSrc = `https://www.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&output=embed`;

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <h2 className="mb-10 lg:text-5xl text-4xl font-bold text-center lg:text-left leading-none text-dark">
        Where to find us
      </h2>
      <div className="map" style={{ width: "100%", height: "450px" }}>
        <iframe
          src={mapSrc}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        />
      </div>
    </div>
  );
};

export default Location;
