import MainBtn from "../buttons/MainBtn";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
const Hero = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col lg:flex-row gap-16 items-center ">
        <div className="lg:pr-10 w-full flex flex-col gap-3">
          <h1 className="mb-4 text-6xl font-bold text-center lg:text-left leading-none text-dark">
            Keep your smile <span className="text-main">clean & great</span>
          </h1>
          <p className="mb-5 text-dark text-lg text-center lg:text-left font-medium">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
          <div className="mb-6">
            <MainBtn />
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center gap-5">
            <div className="flex items-center gap-3 w-full">
              <div className="rounded-2xl p-4 bg-main/30 text-main text-2xl">
                <BiSolidPhoneCall />
              </div>
              <div>
                <p className="font-bold text-lg text-dark">Contact us</p>
                <p className="font-medium text-dark">085 287 8236</p>
              </div>
            </div>
            <div className="flex items-center gap-3 w-full">
              <div className="rounded-2xl p-4 bg-main/30 text-main text-2xl">
                <MdEmail />
              </div>
              <div>
                <p className="font-bold text-lg text-dark">Send us mail</p>
                <p className="font-medium text-dark">contact@example.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <img
            className="object-cover w-full h-64 rounded-3xl sm:h-96"
            src="https://assets-global.website-files.com/6509c07f4854955ed070a8fa/6514b7adb76ede2f950f0cde_our-team-gallery-two-dentistry-x-webflow-template-p-800.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
