import { NavLink } from "react-router-dom";
import { FaRegCalendarCheck } from "react-icons/fa6";


const MainBtn = ({ to }) => {
  return (
    <NavLink to="/booking">
      <button className="main flex flex-col w-full lg:w-fit justify-center items-center">
        <span className="text-lg font-semibold flex items-center gap-3">
          <FaRegCalendarCheck className="text-xl" />
          Book An Appointment
        </span>
      </button>
    </NavLink>
  );
};

export default MainBtn;
